// Adapted from https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews-manual-setup.md

import React, { useEffect, useState } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { usePrismicPreview } from "gatsby-source-prismic";

import { Box, Heading, Wrapper } from "components";

import Page from "../templates/page/";

const RESOLVE_PREVIEW_LINK = (doc) => {
  if (doc.type === "page") {
    return "/" + doc.uid;
  }
  return "/" + doc.slugs[0] + "/";
};

// Note that the `location` prop is taken and provided to the `usePrismicPreview` hook.
const PreviewPage = ({ location }) => {
  const [previewActive, setPreviewActive] = useState("");
  const { isPreview, previewData, path } = usePrismicPreview({
    // The repositoryName value from your `gatsby-config.js`.
    repositoryName: "topazwebsite",
  });

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If this is not a preview, skip.
    //   null = Not yet determined if previewing.
    //   true = Preview is available.
    //   false = Preview is not available.
    if (isPreview === false) return;

    // Save the preview data to somewhere globally accessible. This could be
    // something like a global Redux store or React context.

    // We'll just put it on window.
    window.__PRISMIC_PREVIEW_DATA__ = previewData;

    // Navigate to the correct template
    // Navigate to the correct template
    if (previewData) {
      const keys = Object.keys(previewData);
      if (previewData[keys[0]].type === "page") {
        setPreviewActive(previewData[keys[0]].type);
      } else {
        navigate(RESOLVE_PREVIEW_LINK(previewData[keys[0]]));
      }
    }
  }, [isPreview, previewData, path]);

  const articleData = useStaticQuery(graphql`
    {
      allPrismicArticle(sort: { fields: data___date, order: DESC }) {
        nodes {
          data {
            title {
              text
            }
            excerpt
            link {
              url
            }
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  `);

  return (
    <>
      {!previewActive && (
        <Wrapper as="section" py={[7, 8]}>
          <Box mb={4} mt="128px">
            <Heading size={900} children="Loading..." textAlign="left" />
          </Box>
        </Wrapper>
      )}
      {previewActive === "page" && (
        <Page preview={true} articleData={articleData} />
      )}
    </>
  );
};

export default PreviewPage;
